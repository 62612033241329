<!--******* OCULTO LOS ELEMENTOS QUE NO SERAN VISIBLES PARA EL CATALOGO ******-->

<div class="carrito">
    <!-- <mat-icon matBadge="{{totalArticulos}}" matBadgeColor="warn" style="position: absolute; top: 30%; right: 14%; color: white;" (click)="comprar()">shopping_cart</mat-icon> -->
</div>

<section class="contentPage" style="margin-top: 75px;">
    <section class="product">
        <div class="product__photo">
            <div class="photo-container">
                <div class="photo-main">
                    <img src="" alt="" #imgMuestra id="imgMuestra" width="100%">
                </div>
                <div class="photo-album">
                    <ul>
                        <li tabindex="1"><img src="data:image/gif;base64,{{miArticulo.strImagen1Base64}}" alt="" (click)="changeImg($event)" id="1" width="70px"></li>
                        <li tabindex="2"><img src="data:image/gif;base64,{{miArticulo.strImagen2Base64}}" alt="" (click)="changeImg($event)" id="2" width="70px"></li>
                        <li tabindex="3"><img src="data:image/gif;base64,{{miArticulo.strImagen3Base64}}" alt="" (click)="changeImg($event)" id="3" width="70px"></li>
                        <li tabindex="4"><img src="data:image/gif;base64,{{miArticulo.strImagen4Base64}}" alt="" (click)="changeImg($event)" id="4" width="70px"></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="product__info">
            <div class="title">
                <h1>{{miArticulo.strNombre}}</h1>
                <!-- <span>COD: 45999</span> -->
                <span>{{miArticulo.strNombreGrupo}} / {{miArticulo.strNombreGrupoSub1}}</span>
            </div>
            <div class="price" *ngIf="miArticulo.strMuestraPrecio === 'S' ? true : false">
                <span>{{miArticulo.decPvp1}} </span> €/{{miArticulo.strMedida}} 
            </div>
            <div class="description">
                <h3>DESCRIPCION</h3>
                <p>
                    {{miArticulo.strDescripcion}}
                </p>
            </div>
            <!-- <div class="cantidad">
                <h3>CANTIDAD</h3>

                <div class="input-group">
                    <span class="input-group-btn" (click)="remove()">
                        <button type="button" class="btn btn-default btn-number" disabled="disabled" data-type="minus">
                            <span class="glyphicon glyphicon-minus"></span>
                        </button>
                    </span>
                    <input type="text" class="form-control input-number" min="1" [(ngModel)]="strCantidad" style="text-align: center;">
                    <span class="input-group-btn" (click)="add()">
                        <button type="button" class="btn btn-default btn-number" data-type="plus">
                            <span class="glyphicon glyphicon-plus"></span>
                        </button>
                    </span>
                </div>
                <br>

            </div>
            <div class="Mybuttton">
                <button mat-raised-button (click)="addCarrito()">
                    <mat-icon>add_shopping_cart</mat-icon> Añadir al carrito
                </button>
                <button mat-raised-button color="warn" (click)="comprar()">Comprar</button>
            </div> -->
        </div>
    </section>
    
</section>

<nav class="miSpinner" [style.display]="showSpinner ? 'block' : 'none'" >
	<mat-spinner ></mat-spinner>
</nav>