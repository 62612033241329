<!--****** OCULTO LOS ELEMENTOS QUE NO SERAN VISIBLES EN EL CATALOGO ******-->

<div class="carrito">
  <!-- <mat-icon matBadge="{{totalArticulos}}" matBadgeColor="warn" style="position: absolute; top: 30%; right: 14%; color: white;" (click)="verResumen()">shopping_cart</mat-icon> -->
</div>

<section class="contentPage">

  <div class="buscador">
    <!-- <input type="text" [(ngModel)]="strFiltro" placeholder="Buscar un articulo..." (keyup)="buscar($event)" autofocus [disabled]="disableFiltro ? 'true' : 'false'"> -->
    <input type="text" [(ngModel)]="strFiltro" (keyup)="buscar($event)" placeholder="Buscar un articulo..." autofocus>
    <mat-icon (click)="BtnBuscar()">search</mat-icon>
  </div>

  <div class="tituloSeccion">
      {{titulo}}
  </div>
  


  <mat-grid-list class="listZonas" [cols]="breakpoint" rowHeight="270px" (window:resize)="onResize($event)">      

    <mat-grid-tile *ngFor="let a of miListArticulo">
      <ul>      
        <li (click)="detalle(a)" ><img src="data:image/gif;base64,{{a.strImagen1MiniBase64}}" height="110px"></li>
        <li style="height: 40px;"><div class="textoDescripcion">{{a.strNombre}}</div></li>
        <li><div class="precio" *ngIf="a.strMuestraPrecio === 'S' ? true : false">{{a.decPvp1}} €</div></li>
        <!-- <li><button mat-raised-button (click)="addPedido(a)"><mat-icon>add_shopping_cart</mat-icon> Añadir al carrito</button></li> -->
      </ul>            
  </mat-grid-tile>

  </mat-grid-list>
</section>

<nav class="miSpinner" [style.display]="showSpinner ? 'block' : 'none'" >
	<mat-spinner ></mat-spinner>
</nav>