<div class="example-container">
  <mat-toolbar color="primary" class="example-toolbar">
    <div class="contentMenu">

      <img src="data:image/gif;base64,{{imgLogo}}" width="60px" class="iconoMenu">
      <span class="example-app-name">{{nombreEst}}</span>
      <button mat-icon-button (click)="snav.toggle()" ><mat-icon>menu</mat-icon></button>
    </div>
    <div>
      <mat-icon *ngIf="notLogIn" (click)="logearse()">person_outline</mat-icon> &nbsp;
      <div *ngIf="LogIn"><span class="dot">{{inicialUser}}</span></div>
    </div>
  </mat-toolbar>
  
  <mat-sidenav-container>
    <mat-sidenav #snav class="example-sidenav" mode="side" opened="true">
      <mat-nav-list>
        <mat-list-item style="height: 100%;">
          <span class="full-width miMenu" *ngIf="isExpanded || isShowing" style="font-size: 18px; max-width: 160px; font-weight: 500;">{{textoApp}}</span>
        </mat-list-item>
        <mat-list-item (click)="verArticulos()">
          <span class="full-width miMenu" *ngIf="isExpanded || isShowing">Inicio</span>   
        </mat-list-item>
        <mat-list-item (click)="areaCliente()">
          <span class="full-width miMenu" *ngIf="isExpanded || isShowing">Mis datos</span>   
        </mat-list-item>
        <mat-list-item (click)="verPedidos()">
          <span class="full-width miMenu" *ngIf="isExpanded || isShowing">Mis pedidos</span>   
        </mat-list-item>
        <mat-list-item (click)="logout()">
          <span class="full-width miMenu" *ngIf="isExpanded || isShowing">Salir</span>   
        </mat-list-item>
        <br><br>
      </mat-nav-list>
    </mat-sidenav>
      
    <mat-sidenav-content>
      <router-outlet></router-outlet>
      <br><br><br>
    </mat-sidenav-content>

  </mat-sidenav-container>
</div>

<div class="datosEstablecimiento">
  <div>
    {{miEstablecimiento.strNombre}} {{miEstablecimiento.strTelefono1}} {{miEstablecimiento.strTelefono2}}
    {{miEstablecimiento.strDireccion}} {{miEstablecimiento.strPoblacion}} {{miEstablecimiento.strCp}} {{miEstablecimiento.strProvincia}}
  </div>
</div>